import React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AblaufPage = () => (
  <Layout>
    <Seo
        title="Ablauf Therapie | Psychotherapie Berlin | Verhaltenstherapie Buch"
        description="Ablauf & Phasen einer Psychotherapie in meiner Praxis für Verhaltentherapie in Berlin Buch. Zeitnahes Erstgespräch, freie Plätze auf Anfrage."
    />

    <div className="relative">
        <div className="w-full h-96">
            <StaticImage className="w-full h-full" src="../assets/images/hero-ueber-mich.jpg" alt="" />
            <div className="absolute top-0 w-full h-full opacity-30 bg-gradient-to-r from-transparent via-black to-transparent"></div>
            <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
                <div className="w-full text-center text-white">
                    <h1 className="mb-4 lg:text-4xl">Ablauf</h1>
                    {/* <div className="px-4 sm:text-lg">"Vertrauensvolle Zusammenarbeit auf Augenhöhe ist mir wichtig."</div> */}
                </div>
            </div>
        </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
        <p>Nach einem vereinbarten Erstgespräch finden bis zu 5 probatorische Sitzungen statt, in dessen Rahmen eine ausführliche Diagnostik sowie die Therapieplanung stattfinden. Nach erfolgreicher Antragstellung bei der jeweiligen Versicherung findet eine psychotherapeutische Sitzung in der Regel einmal wöchentlich für 50 Minuten statt. Eine Kurzzeittherapie umfasst bis zu 25 Sitzungen, eine Langzeittherapie bis zu 60 Sitzungen. Es besteht die Möglichkeit, Sitzungen online über end-to-end-verschlüsselte Video-Konferenzen wahrzunehmen.</p>
    </div>
  </Layout>
)

export default AblaufPage